(function ($, Drupal, drupalSettings) {
 Drupal.behaviors.regioncategoriesTabs = {
   attach: function (context, settings) {
    $('.region-content--categories', context).once('regioncategoriesTabs').each(function() {
      $('#region-content .region-content--category').hide();
      $('[id="cat-histoire"]').show();

      $('ul>li:first-child').addClass('active');


      $('.region-content--categories>ul>li').click(function(e){
        e.preventDefault();
        $('.region-content--categories>ul>li').removeClass('active');
        $(this).addClass('active');

        var tab_id = $(this).find('a').attr('href').split("#")[1];
        setTimeout(function() {
          $('html, body').animate({ scrollTop: $('.region-content--categories').offset().top}, 500);
        }, 500);

        $('.region-content--category').each(function() {

          if($(this).attr('id') == tab_id) {
            $(this).fadeIn(300);
          }else {
            $(this).fadeOut(300);

          }
        })
      });
    });
  }
}
})(jQuery, Drupal, drupalSettings);
