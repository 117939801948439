(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.swiperEvents = {
    attach: function (context, settings) {
      $('.swiper-events', context).once('swiperEvents').each(function() {
        new Swiper('.swiper-events', {
          slidesPerView: 4,
          spaceBetween: 0,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
    // when window width is <= 480px
    480: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 2,
    },
    // when window width is <= 640px
    768: {
      slidesPerView: 2,
    }

  }
});
      });
    }
  };
})(jQuery, Drupal, drupalSettings);
