(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.contactMap = {
    attach: function (context, settings) {
      $('#contact-map #map', context).once('contactMap').each(function() {
        var addresses = drupalSettings.lodges;
        var mapid = document.getElementById('map').getAttribute('id');
        var map_style = 'https://api.mapbox.com/styles/v1/louiscarette/cjtedy87x4tln1fpeflmmcyla/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibG91aXNjYXJldHRlIiwiYSI6ImNqdGVkeDVkcTAwZXg0NHA2NmJtdHFiZTQifQ.oF_gjbj1gGnWbsLRyauQow';
        var gitesIcon = L.Icon.extend({
          options: {
            iconSize:     [150, 105]
          }
        });
        if (addresses && mapid) {
          // Map style
          var map = L.map(mapid, {
            scrollWheelZoom: true,
            touchZoom: true,
            dragging: !L.Browser.mobile,
            gestureHandling: true,
          }).setView([50.2181996, 5.3048108], 12);

          map.scrollWheelZoom.disable();


          // Map copyright
          L.tileLayer(map_style, {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,

          }).addTo(map);




          // Markers
          var markerArray = [];
          $.each(addresses, function(index, address) {
            if(address['id'] == 10) {
              var marker = L.marker([address['lat'], address['lon']], {icon: new gitesIcon({iconUrl: drupalSettings.path.baseUrl + "themes/custom/expansion/assets/dist/images/marker_2_.png"})}).addTo(map).bindPopup('<strong>' + addresses[10]['title'] + ' | ' + addresses[11]['title'] + '</strong><br>' + address['address']);
              markerArray.push(marker);
            }
            if(address['id'] == 12) {
              var marker = L.marker([address['lat'], address['lon']], {icon: new gitesIcon({iconUrl: drupalSettings.path.baseUrl + "themes/custom/expansion/assets/dist/images/marker_1__.png"})}).addTo(map).bindPopup('<strong>' + address['title'] + '</strong><br>' + address['address']);
              markerArray.push(marker);
            }

          });

          // Fit map
          var group = L.featureGroup(markerArray);
          var bounds = group.getBounds();
          window.setTimeout(function() {
            if (bounds.isValid()) {
              if ( L.Browser.mobile ) {
               map.fitBounds(bounds);
             }else {
               map.fitBounds(bounds, {padding: [220, 220]});
             }
           } else {
            map.fitWorld();
          }
        }, 500);
        }
      });
    }
  };
})(jQuery, Drupal, drupalSettings);
