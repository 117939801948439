(function($, Drupal, drupalSettings) {
  Drupal.behaviors.menu = {
    attach: function(context, settings) {
      $("#nav", context)
        .once("menu")
        .each(function() {
          $(document).on("click", ".toggle-menu", function() {
            $(this).toggleClass("open");
            $("body").toggleClass("menu-open");
            $(".sublvl > li").each(function(i) {
              var menuEl = $(this);
              setTimeout(function() {
                menuEl.toggleClass("animation");
              }, (i + 1) * 50);
            });
          });

          $(".main-header .wrapper").append(
            $("#nav .cta")
              .clone()
              .addClass("cta-mobile")
          );

          $(".menu-wrapper").each(function() {
            var el = $(this).find("a, span");
            var elActive = $(this).find("a.is-active");

            elActive.parent("li").addClass("active");
            elActive
              .parent("li")
              .parents("li")
              .addClass("active-trail");

            if (
              $(this)
                .children("ul")
                .find("ul").length
            ) {
              $(this)
                .children("ul")
                .addClass("sublvl");
            }

            el.each(function() {
              if ($(this).next("ul").length) {
                $(this).after('<span class="expand"></span>');
              }
            });
          });

          $(".menu-wrapper .menu-item--expanded").click(function() {
            $(this).toggleClass("open");
          });
        });
    }
  };
})(jQuery, Drupal, drupalSettings);
