(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.magnificPopup = {
    attach: function (context, settings) {
      $('.swiper-gallery, .lodge-gallery', context).once('magnificPopup').each(function() {
        $(this).find('a').magnificPopup({
          type:'image',
          gallery: {
            enabled: true,
            preload: [0,2],
            navigateByImgClick: true,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
            tPrev: 'Previous (Left arrow key)',
            tNext: 'Next (Right arrow key)',
            tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
          }
        });
      });
    }
  };
})(jQuery, Drupal, drupalSettings);
