// import TweenMax from "gsap/TweenMax";

import './jsCookie.js'
import './bg.js'
import './galerie.js'
import './lang.js'
import './accordion.js'
import './modal.js'
import './parallax.js'
import './homeSlider.js'
import './magnificPopup.js'
import './sliderEvents.js'
import './categoriesTabs.js'
import './regioncategoriesTabs.js'
import './lodgeGallery.js'
import './paragraph_gallery.js'
import './header.js'
import './menu.js'
import './contact.js'
import './eventPopup.js'
import './event2Popup.js'
