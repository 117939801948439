(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.swiperGallery = {
    attach: function (context, settings) {
      $('.swiper-gallery', context).once('swiperGallery').each(function() {
       new Swiper('.swiper-gallery', {
        slidesPerView: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      });
     });
    }
  };
})(jQuery, Drupal, drupalSettings);
