(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.swiperHomepage = {
    attach: function (context, settings) {
      $('.swiper-container', context).once('swiperHomepage').each(function() {
       new Swiper('.homeSlider', {
        loop: true,
        effect: 'fade',
        autoplay: {
          delay: 3000
        }
      });
     });
    }
  };
})(jQuery, Drupal, drupalSettings);
