
(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.modal = {
    attach: function (context, settings) {
      $('.modal', context).once('modal').each(function() {
        $(this).click(function() {
          $(this).removeClass('open');
        });
        $(this).find(".close").click(function(e) {
          e.preventDefault();
          $(".modal").removeClass('open');
        });
        $('.modal-content').click(function(e) {
          e.stopPropagation();
        });
      });
    }
  }
})(jQuery, Drupal, drupalSettings);
