(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.accordion = {
    attach: function (context, settings) {
      $(".itemAccordion label", context).once('accordion').click(function() {
        $(this).parent().toggleClass('open');
        if($(this).parent().hasClass('open')) {
          var hBlock = $(this).parent().find(".itemAccordionContent .inner").outerHeight();
          $(this).parent().find(".itemAccordionContent").css("max-height", hBlock);
        } else {
          $(this).parent().find(".itemAccordionContent").css("max-height", "0");
        }
      });
    }
  }
})(jQuery, Drupal, drupalSettings);
