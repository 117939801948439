(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.parallax = {
    attach: function (context, settings) {
      $('#parallax', context).once('parallax').each(function() {
          $(this).find('.layer2').paroller({ factor: 0.1, factorXs: 0.4, type: 'foreground', direction: 'vertical' });
          $(this).find('.layer3').paroller({ factor: 0.2, factorXs: 0.5, type: 'foreground', direction: 'vertical' });
     });
    }
  };
})(jQuery, Drupal, drupalSettings);
