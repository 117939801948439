(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.swiper = {
    attach: function (context, settings) {
      $('.lodge-gallery', context).once('swiper').each(function() {
       new Swiper('.lodge-gallery', {
        loop: true,
        slidesPerView: 4,
        autoplay: {
          delay: 2000,
        },
        breakpoints: {
    // when window width is <= 320px
    320: {
      slidesPerView: 1,
    },
    // when window width is <= 480px
    480: {
      slidesPerView: 2,
    },
    // when window width is <= 640px
    640: {
      slidesPerView: 3,
    }
  }
});
     });
    }
  };
})(jQuery, Drupal, drupalSettings);
