
(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.galerieSwiper = {
    attach: function (context, settings) {
      $('.swiper-galerie', context).once('galerieSwiper').each(function() {
        new Swiper('.swiper-galerie', {
          slidesPerView: 3,
          centeredSlides: true,
          spaceBetween: 30,
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
           breakpoints: {
            768: {
              slidesPerView: 1,
              spaceBetween: 30,
            }
          }
        });
      });
    }
  }
})(jQuery, Drupal, drupalSettings);
