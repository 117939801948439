(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.event2Popup = {
    attach: function (context, settings) {
      $('.wrapper-details, .swiper-events', context).once('event2Popup').each(function() {
        $(this).find('.swiper-slide a.popup').magnificPopup({
          type:'image',
          gallery: {
            enabled: true,
            preload: [0,2],
            navigateByImgClick: true,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
            tPrev: 'Previous (Left arrow key)',
            tNext: 'Next (Right arrow key)',
            tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
          }
        });
      });
    }
  };
})(jQuery, Drupal, drupalSettings);
