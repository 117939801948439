(function ($, Drupal) {
 Drupal.behaviors.jsCookie = {
   attach: function (context, settings) {
     $('.alert-cookie', context).once('jsCookie').each(function() {
      var container = $(this);

      var cooInfo = Cookies.get('info');
        console.log(cooInfo);
      if( cooInfo !== "yes") {
        console.log('no');
        container.show();
        $('.alert-cookie .accept').click(function(e) {
          console.log("hello");
          e.preventDefault();
          container.hide();
          Cookies.set('info', 'yes');
        });

      }

    });
   }
 }
})(jQuery, Drupal);
