(function ($, Drupal, drupalSettings) {
  Drupal.behaviors.header = {
    attach: function (context, settings) {
      $('.main-header', context).once('header').each(function() {
        var lastScrollTop = 0;

        $(window).scroll(function(e) {


          var st = $(this).scrollTop();
          if (st > lastScrollTop){
            $('.main-header').removeClass('scrollUp').addClass('scrollDown');
            $('body').removeClass('scrollUp').addClass('scrollDown');
          } else {
            $('.main-header').removeClass('scrollDown').addClass('scrollUp');
            $('body').removeClass('scrollDown').addClass('scrollUp');
          }
          lastScrollTop = st;
          if ($(window).scrollTop() > 60) {
            $('.main-header').addClass('scrolled');
          } else {
            $('.main-header').removeClass('scrolled');
            $('.main-header').removeClass('scrollUp');
            $('.main-header').removeClass('scrollDown');
          }
        });
      });
    }
  }
})(jQuery, Drupal, drupalSettings);
