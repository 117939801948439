(function ($, Drupal) {
 Drupal.behaviors.lang = {
   attach: function (context, settings) {
     $('ul.language-switcher-language-url', context).once('lang').each(function() {
          var container = $(this);

          function langfunction() {
            var activeLang = container.find('li.is-active a').text();

            // create span with active lang
            container.parent().prepend('<span class="label">'+activeLang+'<i class="fas fa-angle-down"></i></span>');

            // hide  active lang in ul
            container.find('li.is-active').remove();

            //show ul
            container.parent().find('.label').on('click', function (){
              container.slideToggle(300);
              $(this).find('.fa').toggleClass('fa-caret-down').toggleClass('fa-caret-up');
            });
          }
          setTimeout(langfunction, .001);

        //hide ul
        $(document).on('click', function(e) {
          var $clicked = $(e.target);
          if (!$clicked.parents().hasClass("lang")) {
            container.slideUp(300);
            container.parent().find('.label').find('.fas').removeClass('fa-angle-up');
            container.parent().find('.label').find('.fas').addClass('fa-angle-down');
          }
        });

        $(document).scroll(function() {
          if ($(document).scrollTop() > 60) {
            container.slideUp(300);
            container.parent().find('.label').find('.fas').removeClass('fa-angle-up');
            container.parent().find('.label').find('.fas').addClass('fa-angle-down');
          }
        });

     });
   }
 }
})(jQuery, Drupal);


