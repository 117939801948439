(function ($, Drupal, drupalSettings) {
 Drupal.behaviors.categoriesTabs = {
   attach: function (context, settings) {
     $('.events-content--category', context).once('categoriesTabs').each(function() {

      $('.categories-events').hide();
      $('.categories-events[data-cat="1"]').show();
      $('.bloc-details:first').addClass('active');

      $('.swiper-slide:first').find('.event').addClass('active');

      $(this).find('a').click(function(e){
        e.preventDefault();
        $('.events-content--category').removeClass('active');
        $(this).parent().addClass('active');
        $('.bloc-details').removeClass('active');
        var attr = $(this).parents().find('.event.active a').attr('href').split('#event');
        var id = attr[1];

        var dataCat = $(this).attr('data-cat');


        $('.categories-events').each(function() {

          if($(this).attr('data-cat') == dataCat) {
            $(this).fadeIn(300);
            $(this).find('.event:first').addClass('active');

          }else {
            $(this).fadeOut(300);
          }
        })

        $('.bloc-details[data-cat="'+dataCat+'"]').each(function(index) {

          if(index == 0) {
            $(this).first().addClass('active');

          }else {
            $(this).removeClass('active');
          }
        })
      });
    });

     $('.get_details').click(function(e) {
      e.preventDefault();
      $('.swiper-slide .event').removeClass('active');
      $(this).parent().addClass('active');
      var href = $(this).attr('href').split('#event');
      var idEvent = href[1];
        setTimeout(function() {
          $('html, body').animate({ scrollTop: $('.events-content--items').offset().top - 120}, 500);
        }, 500);
      $('.bloc-details').each(function() {

        if($(this).attr('data-event') == idEvent) {
          $(this).addClass('active');
        }else {
          $(this).removeClass('active');
        }
      })

    })
   }
 }
})(jQuery, Drupal, drupalSettings);
