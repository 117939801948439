// -------------------------------------------------------------
//   Data to BG
//   <div data-bg="path/to/image.jpg"></div>
// -------------------------------------------------------------

(function ($, Drupal, drupalSettings) {
 Drupal.behaviors.databg = {
   attach: function (context, settings) {
     $('*[data-bg]', context).once('databg').each(function() {
      var bg = $(this).data('bg');
      if (bg.substr(0, 1) === '#') {
        $(this).css({
          'background-color': bg
        });
      } else {
        $(this).css({
          'background-image': 'url('+bg+')'
        });
      }
     });
   }
 }
})(jQuery, Drupal, drupalSettings);
